export const optionsFilterDate = [
    { key: 'lastWeek', label: 'Week', value: '-isoWeek' },
    //{ key: 'last7Days', label: 'Last 7 days', value: 7 },
    { key: 'thisMonth', label: 'Month', value: 'month' },
    { key: 'fiscalYear', label: 'fiscal year',
        value: () => {
            if (this.$moment().isAfter(this.$moment([this.$moment().year(), 5]).endOf('month'))) {
                return {
                    start: this.$moment([this.$moment().year(), 6]),
                    end: this.$moment([this.$moment().add(1,'year').year(), 5]).endOf('month'),
                }
            }
            else {
                return {
                    start: this.$moment([this.$moment().subtract(1, 'years').year(), 6]),
                    end: this.$moment([this.$moment().year(), 5]).endOf('month'),
                }
            }
        },
    },
    { key: 'prevWeek', label: 'Previous Week', value: () => {
            return {
                start: this.$moment().subtract(2, 'week').startOf('isoWeek'),
                end:  this.$moment().subtract(2, 'week').endOf('isoWeek'),
            }
        },
    },
    { key: 'lastMonth', label: 'Previous month', value: '-month' },
    //{ key: 'last30Days', label: 'Last 30 days', value: 30 },
    {
        key: 'prevFiscalYear',
        label: 'Previous fiscal year',
        value: () => {
            if( this.$moment().isAfter(this.$moment([this.$moment().year(), 5]).endOf('month'))) {
                return {
                    start: this.$moment([this.$moment().subtract(1, 'year').year(), 6]),
                    end: this.$moment([this.$moment().year(), 5]).endOf('month'),
                }
            }
            else {
                return {
                    start: this.$moment([this.$moment()
                        .subtract(2, 'years').year(), 6]),
                    end: this.$moment([this.$moment()
                        .subtract(1, 'years').year(), 5]).endOf('month'),
                }
            }
        },
    },
]