<template>

    <div v-if="mobile" class="h-auto w-full z-40">

        <div v-if="Mobilepath == 'PersonalDashboard'" class="w-full">

            <div class="w-full bg-filters rounded-t-lg shadow-md bottom-0 flex flex-col" :class="{'h-8': (!openFilters && !showComments), 'h-122': openFilters || showComments}" style="transition: all .5s">
                
                <div class="h-8 flex flex-row justify-start items-center">

                    <div class="h-full flex-1 flex flex-row justify-start items-center pl-4">

                        <div v-if="!openFilters" class="h-full flex-1 flex flex-col justify-center
                            items-center"  @click="openFilters = !openFilters">
                            <i class="mdi mdi-chevron-up text-lg move"></i>
                            <span class="text-xxs -mt-1">Visualiza las secciones</span>
                        </div>

                        <div v-else class="h-full flex-1 flex flex-col justify-center
                            items-center"  @click="openFilters = !openFilters">
                            <i class="mdi mdi-chevron-down text-lg move"></i>
                            <span class="text-xxs -mt-1">Ocultar las secciones</span>
                        </div>

                    </div>

                </div>

                <div class="flex-1 flex flex-col">

                    <div v-if="openFilters" class="h-full flex flex-col">

                        <separator direction="horizontal" />

                        <div class="h-full flex flex-col justify-start items-center py-6 px-2">

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('HomePersonalDashboard')">
                                <div class="h-8 w-8 rounded-lg bg-good-30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-view-dashboard-variant text-good"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Comms Dashboard Home</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('TheEssentialMobile')">
                                <div class="h-8 w-8 rounded-lg bg-warning-30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-calendar-star text-warning"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">The Essential</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('AlertsMobile')">
                                <div class="h-8 w-8 rounded-lg bg-danger-30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-message-alert-outline text-danger"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Alerts</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('TalkingPointsMobile')">
                                <div class="h-8 w-8 rounded-lg bg-good-30 mr-2 flex flex-row justify-center
                                    items-center">
                                    <i class="mdi mdi-comment-multiple-outline text-good"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Talking Points & Firm Docs</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('MediaMobile')">
                                <div class="h-8 w-8 rounded-lg bg-gold-30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-newspaper-variant-multiple-outline text-gold"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Media Clipping</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('EventsMobile')">
                                <div class="h-8 w-8 rounded-lg bg-violet_30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-calendar-star text-violet"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">EY Events</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('WinsMobile')">
                                <div class="h-8 w-8 rounded-lg bg-green-30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-trophy-award text-green"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Wins</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center px-2 mb-4"
                                @click="goTo('CommonsPodcastsMobile')">
                                <div class="h-8 w-8 rounded-lg bg-purple-30 mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-podcast text-purple"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Comms & Podcasts</span>
                            </div>

                            <div class="h-auto w-full flex flex-row justify-start items-center mb-4 px-2"
                                @click="goTo('Tipsanddocs')">
                                <div class="h-8 w-8 rounded-lg bg-blue mr-2 flex flex-row justify-center items-center">
                                    <i class="mdi mdi-file" style="color: #13F2E3"></i>
                                </div>
                                <span class="text-white text-sm font-semibold">Tips & Docs</span>
                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div v-else class="h-full w-full">

            <div class="w-full h-full">
        
                <div class="h-12 w-full rounded-t-md bg-filters flex flex-row justify-end px-2 items-center">

                    <div v-if="Mobilepath === 'pipeline'" class="h-full flex-1 flex flex-row justify-start items-center px-2">

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath === '/mobile/pipeline/SLSMU',
                            'bg-module-top': completePath !== '/mobile/pipeline/SLSMU'}" @click="goTo('SLSMU')">
                            <i class="text-sm mdi mdi-view-split-vertical text-success"></i>
                        </div>

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath === '/mobile/pipeline/Circles',
                            'bg-module-top': completePath !== '/mobile/pipeline/Circles'}" @click="goTo('Circles')">
                            <i class="text-sm mdi mdi-google-circles text-success"></i>
                        </div>

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath === '/mobile/pipeline/Opportunities',
                            'bg-module-top': completePath !== '/mobile/pipeline/Opportunities'}" @click="goTo('Opportunities')">
                            <i class="text-sm mdi mdi-view-list text-success"></i>
                        </div>

                    </div>

                    <div v-if="Mobilepath === 'ProjectsManagement'" class="h-full flex-1 flex flex-row justify-start items-center px-2">

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath === '/mobile/ProjectsManagement/projectsManagementSLSMU',
                            'bg-module-top': completePath !== '/mobile/ProjectsManagement/projectsManagementSLSMU'}"
                            @click="goTo('projectsManagementSLSMU')">
                            <i class="text-sm mdi mdi-view-split-vertical text-success"></i>
                        </div>

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath === '/mobile/ProjectsManagement/ProjectsProjects',
                            'bg-module-top': completePath !== '/mobile/ProjectsManagement/ProjectsProjects'}"
                            @click="goTo('ProjectsProjects')">
                            <i class="text-sm mdi mdi-view-list text-success"></i>
                        </div>

                        <div v-if="tnrpermissions" class="h-5 w-6 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath == '/mobile/ProjectsManagement/ProjectsProjects/TnrMobile',
                            'bg-module-top': completePath !== '/mobile/ProjectsManagement/ProjectsProjects/TnrMobile'}"
                            @click="goTo('TnrMobile')">
                            <!-- <i class="text-sm mdi mdi-view-list text-success"></i> -->
                            <span class="text-3xs text-aux">TNR</span>
                        </div>

                    </div>

                    <div v-if="Mobilepath === 'Chargeability'" class="h-full flex-1 flex flex-row justify-start items-center px-2">

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center
                            items-center" :class="{'bg-success-30': completePath === '/mobile/Chargeability/SLSMUChargeability',
                            'bg-module-top': completePath !== '/mobile/Chargeability/SLSMUChargeability'}" @click="goTo('SLSMUChargeability')">
                            <i class="text-sm mdi mdi-view-list text-success"></i>
                        </div>
                    </div>

                    <div v-if="Mobilepath === 'InterIntra'" class="h-full flex-1 flex flex-row
                        justify-start items-center px-2">

                        <div class="h-5 w-5 mr-2 rounded-md flex flex-col justify-center items-center"
                                :class="{'bg-success-30': completePath === '/mobile/InterIntra/SwipSLSMU',
                                'bg-module-top': completePath !== '/mobile/InterIntra/SwipSLSMU'}"
                            @click="goTo('SwipSLSMU')">
                            <i class="text-sm mdi mdi-view-list text-success"></i>
                        </div>
                    </div>

                    <div v-if="!openFilters && !showComments" class="h-5 w-5 bg-module-top rounded-md flex flex-col justify-center items-center mr-2" @click="toggleComments()">
                        <i class="mdi text-aux text-xs mdi-comment-outline"></i>
                    </div>

                    <div v-if="showComments && !openFilters" class="h-5 w-10 -mr-2 rounded-md flex flex-col justify-center items-center" @click="toggleComments()">
                        <i class="mdi text-aux text-xs mdi-close"></i>
                    </div>

                    <div v-if="!showComments && !openFilters" class="h-5 w-5 bg-module-top rounded-md flex flex-col justify-center items-center" @click="toggleFilters()">
                        <i class="mdi text-aux text-xs mdi-dots-vertical"></i>
                    </div>

                    <div v-if="openFilters && !showComments" class="h-5 w-10 rounded-md -mr-2 flex flex-col justify-center items-center mr-2" @click="toggleFilters()">
                        <i class="mdi text-aux text-xs mdi-close"></i>
                    </div>

                </div>

                <div class="bg-filters w-full flex flex-col" :class="{'h-110': (openFilters || showComments), 'h-0 overflow-hidden': !(openFilters && !showComments)}" style="transition: all .3s;" >

                    <div v-if="openFilters" class="h-full w-full flex flex-col overflow-auto">

                        <separator direction="horizontal" />
                        
                        <div class="px-4 flex flex-col py-2 h-16 justify-center">
                            <span class="text-xs text-font-gray mb-1 font-semibold">Period</span>

                            <VueCtkDateTimePicker
                                v-model="timeModel"
                                :dark="true"
                                :format="timeFormat"
                                :formatted="'D [de] MMM[ de] YYYY'"
                                :range="true"
                                :only-date="true"
                                :no-shortcuts="true"
                                :auto-close="false"
                                :no-button-now="false"
                                @input="input"
                                :custom-shortcuts="customShortcuts"
                                input-size="sm"
                                />

                        </div>

                        <separator />

                        <div class="px-4 flex flex-col py-2 h-16 justify-center">

                            <span class="text-xs font-semibold text-font-gray mb-2">Current <span class="text-aux">Project Management</span> Data</span>

                            <div class="" >

                                <div class="flex h-4">

                                    <div v-for="(period, key, index) in periodFilters" :key="index" class="flex flex-col justify-center items-center text-xxs -ml-px border border-aux py-2 w-full overflow-hidden cursor-pointer" :class="{ 'rounded-l-md':key === 0, 'rounded-r-md'
                                    :key === (Object.keys(periodFilters).length - 1), 'bg-aux-15 text-aux':selectedPeriod === period.id }" @click="selectPeriod(key)">

                                    <span class="text-3xs">{{ periodfilter(period.name) }}</span>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <separator v-if="!ifInterIntraExtend" />

                        <div class="px-4 py-2 flex flex-col justify-center h-16" v-if="selectedRolTemp && !ifInterIntraExtend">

                            <span class="text-xs text-font-gray font-semibold mb-1">Available roles</span>

                            <multiselect :multiple="false"
                                v-model="selectedRolTemp"
                                :custom-label="getLabel"
                                :options="userData.roles"
                                :allow-empty="false"
                                @select="getFilters"
                                />

                        </div>

                        <separator />

                        <div v-if="seeDataAsOptions && !ifInterIntraExtend" class="p-2 px-4 flex flex-col">

                            <div v-if="!otherToken" class="box-title mb-2 text-xs">Select User</div>

                            <multiselect v-if="!otherToken" :multiple="false"
                                v-model="seeDataAs"
                                :custom-label="getLabelSeeDataAs"
                                :options="seeDataAsOptions"
                                :allow-empty="false"
                            />

                            <div v-if="otherToken" class="h-6 w-1/2 flex flex-row justify-center items-center bg-aux bg-opacity-50 rounded-lg m-auto cursor-pointer" @click="setNewToken('a')">
                                <span class="text-body font-semibold text-xs">Go back to admin</span>
                            </div>

                        </div>

                        <separator v-if="!ifInterIntraExtend" class="mt-1"/>

                        <span class="text-xs font-semibold text-font-gray pt-2 px-4" v-if="filters && Object.keys(filters).length">Hierarchy filters</span>

                        <div v-if="loading" class="h-20 relative">
                            <loader :loading="loading" />
                        </div>

                        <div class="relative w-full">

                            <transition name="fade">

                                <div class="px-4 py-1 w-full" v-if="!loading">

                                    <template v-if="filters">

                                        <div class="flex items-center mb-1 min-h-8" v-for="(filter, key) in filters" :key="key" v-if="filter.length && key == 'sl_zeus'">

                                            <div class="w-20 text-font-gray text-xs uppercase">{{ key!='sl_zeus'?key:'SL' }}</div>
                                           
                                            
                                            <!-- <div v-if="!ifInterIntraExtend" class="w-full flex flex-1 min-w-0"> -->
                                            <div v-if="!interIntraSL" class="w-full flex flex-1 min-w-0">
                                                <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true"  @search-change="asyncFind($event, key)"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative" size="mini" :option-height="10">
                                                    <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                        <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                            <i class="mdi mdi-chevron-double-down"></i>View more
                                                            <span class="text-font-gray">
                                                                (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <template slot="beforeList" v-if="loadingPage">
                                                        <loader :loading="loadingPage" />
                                                    </template>
                                                </multiselect>
                                                
                                            </div>
                                            <div v-else class="h-full w-full flex flex-row justify-start items-center text-xs ml-6">
                                                <span class="text-aux">
                                                    {{ $route.params.catId }} 
                                                   
                                                </span>
                                            </div>

                                        </div>

                                        <div class="flex items-center mb-1 min-h-8 relative" v-for="(filter, key) in filters" :key="key" v-if="filter.length && key == 'competencyIdWithRegionId'">

                                            <loader :loading="loadingSMU" />

                                            <div class="w-20 text-font-gray uppercase text-xs">SMU</div>

                                            <div class="w-full flex flex-1 min-w-0">
                                                <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true" @search-change="asyncFind($event, key)"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">

                                                    <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                        <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                            <i class="mdi mdi-chevron-double-down"></i>View more
                                                            <span class="text-font-gray">
                                                                (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                            </span>
                                                        </div>
                                                    </div>

                                                    <template slot="beforeList" v-if="loadingPage">
                                                        <loader :loading="loadingPage" />
                                                    </template>

                                                </multiselect>
                                            </div>

                                        </div>

                                        <div class="flex items-center mb-1 min-h-8" v-for="(filter, key) in filters" :key="key" v-if="filter.length && key == 'sector'">

                                            <div class="w-20 text-font-gray text-xs uppercase">{{ key!='sl_zeus'?key:'SL' }}</div>

                                            <div class="w-full flex flex-1 min-w-0">

                                                <multiselect :multiple="true" v-model="selectedFilters[key]" @search-change="asyncFind($event, key)" :searchable="true"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative z-30">
                                                    <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                        <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                            <i class="mdi mdi-chevron-double-down"></i>View more
                                                            <span class="text-font-gray">
                                                                (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <template slot="beforeList" v-if="loadingPage">
                                                        <loader :loading="loadingPage" />
                                                    </template>
                                                </multiselect>

                                            </div>

                                        </div>

                                        <div class="flex items-center mb-1 min-h-8 " v-for="(filter, key) in filters" :key="key" v-if="filter.length && key == 'udn'">

                                            <div class="w-20 text-font-gray text-xs uppercase">CLIENTE</div>

                                            <div class="w-full flex flex-1 min-w-0">
                                                <multiselect :multiple="true" v-model="selectedFilters[key]" @search-change="asyncFind($event, key)" :searchable="true"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">
                                                    <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                        <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                            <i class="mdi mdi-chevron-double-down"></i>View more
                                                            <span class="text-font-gray">
                                                                (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <template slot="beforeList" v-if="loadingPage">
                                                        <loader :loading="loadingPage" />
                                                    </template>
                                                </multiselect>
                                            </div>

                                        </div>

                                        <div class="flex items-centermb-1 min-h-8" v-for="(filter, key) in filters" :key="key" v-if="filter.length && key == 'ep'">

                                            <div class="w-20 text-font-gray text-xs uppercase">{{ key!='sl_zeus'?key:'SL' }}</div>

                                            <div class="w-full flex flex-1 min-w-0">
                                                <multiselect :multiple="true" v-model="selectedFilters[key]" @search-change="asyncFind($event, key)" :searchable="true"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">
                                                    <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                        <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                            <i class="mdi mdi-chevron-double-down"></i>View more
                                                            <span class="text-font-gray">
                                                                (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <template slot="beforeList" v-if="loadingPage">
                                                        <loader :loading="loadingPage" />
                                                    </template>
                                                </multiselect>
                                            </div>

                                        </div>

                                    </template>

                                </div>

                            </transition>

                            <loader :loading="loading" class="py-14"/>

                        </div>

                        

                    </div>
                    
                    <div v-if="openFilters" class="h-8 flex flex-row mt-auto">

                        <div class="h-full w-1/2 flex flex-row justify-center items-center p-1">
                            <div class="btn-gray h-full flex flex-col justify-center items-center" @click="setDefaultOptions">
                                <span class="text-xxs">Reset</span>
                            </div>
                        </div>

                        <div class="h-full w-1/2 flex flex-row justify-center items-center p-1 relative">
                            <div class="absolute h-full w-full top-0 bg-filters-80 z-30"
                                v-if="!seeFilters"></div>
                            <div class="btn-aux h-full flex flex-col justify-center items-center" @click="applyFilters">
                                <span class="text-xxs">Filter</span>
                            </div>
                        </div>

                    </div>

                    <div v-if="showComments" class="h-full w-full flex flex-col">

                        <comments-container :mobile="true" class="h-full"/>

                    </div>

                </div>

            </div>
            
        </div>

    </div>

    <div v-else class="h-full flex flex-col px-2 overflow-hidden">

        <div class="flex flex-1 min-h-0 overflow-y-scroll flex-col">

            <div v-if="!ifPersonalDashboard" class="p-2 flex flex-col h-24">
                <span class="box-title mb-4">Period</span>
                <VueCtkDateTimePicker
                        v-model="timeModel"
                        :dark="true"
                        :format="timeFormat"
                        :formatted="'D [de] MMM [de] YYYY'"
                        :range="true"
                        :only-date="true"
                        :no-shortcuts="true"
                        :auto-close="false"
                        :no-button-now="false"
                        @input="input"
                        :custom-shortcuts="customShortcuts"
                />
            </div>

            <separator class="mt-4" v-if="!ifPersonalDashboard"/>

            <div v-if="!ifPersonalDashboard" class="p-2 py-4 flex flex-col">
                <div class="box-title mb-4">Current <span class="text-aux">Project Management</span> Data</div>
                <div v-if="periodFilters" class="" >
                    <div class="flex">
                        <div v-for="(period, key, index) in periodFilters" :key="index"
                            class="text-center -ml-px border border-aux py-2 w-full cursor-pointer"
                            :class="{ 'rounded-l-lg':key === 0, 'rounded-r-lg'
                                    :key === (Object.keys(periodFilters).length - 1),
                                    'bg-aux-15 text-aux':selectedPeriod === period.id }"
                            @click="selectPeriod(key)">
                            {{ periodfilter(period.name) }}
                        </div>
                    </div>
                </div>
            </div>

            <separator v-if="!ifPersonalDashboard " />

            <div class="p-4 h-20 flex flex-col h-32" v-if="selectedRolTemp && !ifInterIntraExtend">
                <div class="box-title mb-4">Available roles</div>
                <multiselect :multiple="false"
                    v-model="selectedRolTemp"
                    :custom-label="getLabel"
                    :options="userData.roles"
                    :allow-empty="false"
                    @select="getFilters"
                />
            </div>

            <separator v-if="selectedRolTemp && !ifInterIntraExtend" />

            <div v-if="seeDataAsOptions && !ifInterIntraExtend" class="p-4 h-20 flex flex-col h-32">

                <div v-if="!otherToken" class="box-title mb-4">Select User</div>

                <multiselect v-if="!otherToken" :multiple="false"
                    v-model="seeDataAs"
                    :custom-label="getLabelSeeDataAs"
                    :options="seeDataAsOptions"
                    :allow-empty="false"
                />

                <div v-if="otherToken" class="h-12 w-1/2 flex flex-row justify-center items-center bg-aux bg-opacity-50 rounded-lg m-auto cursor-pointer" @click="setNewToken('a')">
                    <span class="text-body font-semibold">Go back to admin</span>
                </div>

            </div>

            <separator v-if="!ifPersonalDashboard && !ifInterIntraExtend" />

            <div v-if="!ifPersonalDashboard" class="h-auto flex flex-col p-4 relative">

                <loader :loading="loading" />

                <span class="box-title mb-2" v-if="filters && Object.keys(filters).length">Hierarchy filters</span>

                <div class="flex-1 min-h-0 flex flex-col">

                    <transition name="fade" class="py-10">

                        <div class="py-4" v-if="!loading">

                            <template v-if="filters">

                                <div class="flex items-center mb-3 flex-none min-h-10" v-for="(filter, key) in filters" :key="key" v-if="key == 'sl_zeus'">

                                    <div class="w-24 text-font-gray uppercase">{{ key!='sl_zeus'?key:'SL' }}</div>

                                    <div v-if="!ifInterIntraExtend" class="w-full">
                                        <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true" @search-change="asyncFind($event, key)"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">

                                            <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">

                                                <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                    <i class="mdi mdi-chevron-double-down"></i>View more
                                                    <span class="text-font-gray">
                                                        (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                    </span>
                                                </div>

                                            </div>

                                            <template slot="beforeList" v-if="loadingPage">
                                                <loader :loading="loadingPage" />
                                            </template>

                                        </multiselect>
                                    </div>

                                    <div v-else class="h-full w-full flex flex-row justify-start items-center">
                                        <span class="text-aux">
                                            {{ $route.params.catId }}
                                        </span>
                                    </div>

                                </div>

                                <div class="flex items-center mb-3 flex-none min-h-10 relative" v-for="(filter, key) in filters" :key="key" v-if="key == 'competencyIdWithRegionId'">

                                    <loader :loading="loadingSMU" />

                                    <div class="w-24 text-font-gray uppercase">SMU</div>

                                    <div class="w-full">

                                        <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true" @search-change="asyncFind($event, 'competencyIdWithRegionId')"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">

                                            <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                    <i class="mdi mdi-chevron-double-down"></i>View more
                                                    <span class="text-font-gray">
                                                        (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                    </span>
                                                </div>
                                            </div>

                                            <template slot="beforeList" v-if="loadingPage">
                                                <loader :loading="loadingPage" />
                                            </template>

                                        </multiselect>

                                    </div>

                                </div>

                                <div class="flex items-center mb-3 flex-none min-h-10" v-for="(filter, key) in filters" :key="key" v-if="key == 'sector'">

                                    <div class="w-24 text-font-gray uppercase">{{ key!='sl_zeus'?key:'SL' }}</div>

                                    <div class="w-full">
                                        <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true"  @search-change="asyncFind($event, key)"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">
                                            <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                    <i class="mdi mdi-chevron-double-down"></i>View more
                                                    <span class="text-font-gray">
                                                        (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                    </span>
                                                </div>
                                            </div>
                                            <template slot="beforeList" v-if="loadingPage">
                                                <loader :loading="loadingPage" />
                                            </template>
                                        </multiselect>
                                    </div>

                                </div>

                                <div class="flex items-center mb-3 flex-none min-h-10 relative" v-for="(filter, key) in filters" :key="key" v-if="key == 'udn'">

                                    <loader :loading="loadingUdn" />

                                    <div class="w-24 text-font-gray uppercase">CLIENTE</div>

                                    <div class="w-full">
                                        <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true"  @search-change="asyncFind($event, key)"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">
                                            <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                    <i class="mdi mdi-chevron-double-down"></i>View more
                                                    <span class="text-font-gray">
                                                        (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                    </span>
                                                </div>
                                            </div>
                                            <template slot="beforeList" v-if="loadingPage">
                                                <loader :loading="loadingPage" />
                                            </template>
                                        </multiselect>
                                    </div>

                                </div>

                                <div class="flex items-center mb-3 flex-none min-h-10" v-for="(filter, key) in filters" :key="key" v-if="key == 'ep'">

                                    <div class="w-24 text-font-gray uppercase">{{ key!='sl_zeus'?key:'SL' }}</div>

                                    <div class="w-full">
                                        <multiselect :multiple="true" v-model="selectedFilters[key]" :searchable="true" @search-change="asyncFind($event, key)"
                                                    label="filter_name" trackBy="filter_id" :options="filter" class="relative">
                                            <div slot="afterList" v-show="paginations[key].current_page < paginations[key].last_page">
                                                <div class="bg-good-30 hover:bg-good-50 p-2 cursor-pointer text-white" @click="loadMoreFilters(key)">
                                                    <i class="mdi mdi-chevron-double-down"></i>View more
                                                    <span class="text-font-gray">
                                                        (page: {{ paginations[key].current_page }} / {{ paginations[key].last_page }})
                                                    </span>
                                                </div>
                                            </div>
                                            <template slot="beforeList" v-if="loadingPage">
                                                <loader :loading="loadingPage" />
                                            </template>
                                        </multiselect>
                                    </div>

                                </div>

                            </template>

                        </div>

                    </transition>

                </div>

            </div>

        </div>

        <div class="h-12 mt-auto flex flex-row justify-end items-center ml-auto p-2">

            <div class="h-full w-auto flex flex-col justify-center items-center">
                <div class="btn-gray m-2 h-full" @click="setPeriod">Reset</div>
            </div>

            <div class="h-full w-auto flex flex-col justify-center items-center relative">
                <div class="absolute h-full w-full top-0 bg-filters-80 z-30"
                    v-if="!seeFilters"></div>
                <div class="btn-aux h-full" @click="applyFilters">Filter</div>
            </div>

        </div>

    </div>

</template>

<script>
    import { actions, state } from '@/store'
    import {optionsFilterDate} from '@/utils/OptionsFilterDate'
    import axios from 'axios'
    import Separator from './Separator.vue';
    import commentsContainer from './CommentsContainer.vue';

    const CancelToken = axios.CancelToken;
    let cancel;

    export default {
        components:{ 
            Separator,
            commentsContainer
        },
        props:['mobile'],
        data() {
            return {
                query: "",
                results: "",
                loading: false,
                loadingPage: false,
                timeModel: null,
                selectedRolTemp: null,
                selectedPeriod: state.user.period,
                selectedPeriodName: null,
                paginations: {},
                periodFilters: {},
                customShortcuts: optionsFilterDate,
                hierarchyFilters:{},
                selectedFilters: false,
                testPeriod:false,
                loadingSMU:false,
                loadingUdn:false,
                openFilters: false,
                showComments: false,
                generalfilters: false,
                seeDataAs:null,
                seeDataAsOptions:null,
            }
        },
        methods: {
            clear() {
                this.loadingPage = false;
                this.results = "";
                this.query = "";
            },
            handleSearch: _.debounce(function() {
                this.asyncFind();
            }, 300),
            asyncFind (query, id) {
                if (cancel !== undefined) {
                    cancel();
                }
                this.searchFilter(id, query)
            },
            setDefaultOptions() {

                if(!this.selectedRolTemp){
                    this.selectedRolTemp = this.defaultSelectedRol
                    actions.setRolSelected(this.selectedRolTemp)
                }
                // this.timeModel = state.defaultTimePeriod
                // this.setPeriod();
                this.selectedFilters = {}
                this.getFilters();
                this.getPeriods();
                this.getSeeDataAs();
                this.applyFilters();

            },
            getFilters(params) {

                let callParams

                if (params) {

                    callParams = {
                        roleTypeId: params.role_type_id,
                        gui_role_specific_id:params.gui_role_specific_id
                    }

                }

                else {

                    callParams =  {
                        roleTypeId:this.selectedRolTemp.role_type_id,
                        gui_role_specific_id:this.selectedRolTemp.gui_role_specific_id
                    }

                }

                this.loading = true
                this.hierarchyFilters = {}
                this.selectedFilters = {}
                //actions.emptyFilters();

                actions.setRolesParams(params);

                if(state.user.screen != 3){

                    this.axios.get('filters/filters', { params: callParams}).then(response => {

                        this.hierarchyFilters = {}

                        // setTimeout( () => {

                            for (let i in response.data.data) {

                                for (let j in response.data.data[i]) {

                                    this.$set(this.paginations, j, {})

                                    this.axios.get('filters/' + j, { params: callParams }).then(responseTwo => {

                                        this.$set(this.paginations, j, responseTwo.data.meta)
                                        this.$set(this.hierarchyFilters, j,  responseTwo.data.data)

                                    })
                                }
                            }

                            this.loading = false

                        // },500)

                    });

                }
            },
            getPeriods(){
                this.axios.get('user/periods')
                    .then(response => {
                        this.selectedPeriodName = response.data.data[2].name;
                        this.periodFilters= response.data.data;
                        // this.data.selectedPeriod = this.selectedPeriod;
                    })
            },
            searchFilter(j, search) {
                this.loadingPage = true
                this.paginations[j].current_page++
                let params =  {
                    roleTypeId: this.selectedRolTemp.role_type_id,
                    gui_role_specific_id: this.selectedRolTemp.gui_role_specific_id,
                    period: this.selectedPeriod,
                    filter_name: search,
                    sector: j == 'udn' ? this.sectorSelected : null,
                    sl_zeus: j == 'competencyIdWithRegionId' ? this.slSelected : null
                }
                this.axios({
                    method: "get",
                    url: 'filters/' + j,
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c;
                    }),
                    params: params
                }).then(responseTwo => {
                    this.$set(this.paginations, j, responseTwo.data.meta)
                    // this.hierarchyFilters[j] = [...this.hierarchyFilters[j] , ...responseTwo.data.data]
                    this.hierarchyFilters[j] = [...responseTwo.data.data]
                    this.hierarchyFilters[j] = this._.uniq(this.hierarchyFilters[j])
                    this.loadingPage = false
                }).catch(err => {
                    this.loadingPage = false;
                    this.results = err.message;
                })

            },
            loadMoreFilters(j) {

                this.loadingPage = true

                if (this.paginations[j].current_page < this.paginations[j].last_page) {
                    this.paginations[j].current_page++
                }

                let params =  {
                    ...this.periodParams,
                    roleTypeId: this.selectedRolTemp.role_type_id,
                    page: this.paginations[j].current_page,
                    gui_role_specific_id: this.selectedRolTemp.gui_role_specific_id,
                    period: this.selectedPeriod,
                    sector: j == 'udn' ? this.sectorSelected : null,
                    sl_zeus: j == 'competencyIdWithRegionId' ? this.slSelected : null
                }

                this.axios.get('filters/' + j, {
                    params: params
                }).then(responseTwo => {
                    this.$set(this.paginations, j, responseTwo.data.meta)
                    this.hierarchyFilters[j] = [...this.hierarchyFilters[j] , ...responseTwo.data.data]
                    this.loadingPage = false
                })

            },
            getLabel(role) {
                if (role.role_type_link !== 'presidencia' && role.role_type_link !== 'EP') {
                    return `${ role.role_type_name } / ${ role.gui_role_name }`
                }
                return `${ role.role_type_name }`
            },
            getLabelSeeDataAs(option){
                return option.name
            },
            applyFilters() {

                this.openFilters = false
                actions.setGlobalParams(this.componetParams)
                actions.setGlobalParamsPretty(this.componetParamsPretty)
                actions.setRolSelected(this.selectedRolTemp)
                actions.setPeriodSelected(this.selectedPeriodName)
                this.setNewToken(this.seeDataAs)

            },
            input(e) {
                // if (this.selectedPeriod === 'week') {
                //     this.timeModel = {
                //         start: this.$moment(e.start).startOf('isoWeek'),
                //         end: this.$moment(e.start).endOf('isoWeek'),
                //     }
                // }
                // if (this.selectedPeriod === 'month') {
                //     this.timeModel = {
                //         start: this.$moment(e.start).startOf('month'),
                //         end: this.$moment(e.start).endOf('month'),
                //     }
                // }
                // if (this.selectedPeriod === 'year') {
                //     if( this.$moment(e.start).isAfter(this.$moment([this.$moment(e.start).year(), 5]).endOf('month'))){
                //         this.timeModel = {
                //             start: this.$moment([this.$moment(e.start).year(), 6]),
                //             end: this.$moment([this.$moment(e.start).add(1,'year').year(), 5]).endOf('month'),
                //         }
                //     }
                //     else {
                //         this.timeModel = {
                //             start: this.$moment([this.$moment(e.start).subtract(1, 'years').year(), 6]),
                //             end: this.$moment([this.$moment(e.start).year(), 5]).endOf('month'),
                //         }
                //     }
                // }
            },
            selectPeriod(key) {
                this.selectedPeriod = this.periodFilters[key].id;
                this.selectedPeriodName = this.periodFilters[key].name;
                // this.input(this.timeModel)
            },
            setPeriod(){

                this.axios.get('filters/default_dates').then(response => {

                    let period = false

                    period = {
                        start: response.data.startDate,
                        end: response.data.endDate
                    }

                    actions.setDefaultTimePeriod(period)
                    this.timeModel = period


                    // this.testPeriod = true
                    setTimeout(() => {
                        this.setDefaultOptions();
                    }, 100);

                })

            },
            periodfilter(period){

                switch (period) {
                    case 'Week Data':
                        return 'PW'
                        break;
                    case 'Month Data':
                        return 'MTD'
                        break;
                    case 'FY Data':
                        return 'FYTD'
                        break;
                    case 'Accumulated':
                        return 'ETD'
                        break;
                }

            },
            search(data) {
                if (data) {
                    return data.filter(post => {
                        return post['opportunity_name'].toLowerCase()
                            .includes(this.searchInput.toLowerCase()) || post['probability']
                            .toLowerCase().includes(this.searchInput.toLowerCase())
                    })
                } else { return [] }
            },
            smuFilteredSL(sl){

                let paramssl = null

                setTimeout(() => {

                    this.loadingSMU = true
                    
                    if(sl){

                        paramssl = {
                            ...this.params,
                            sl_zeus: sl
                        }

                    } else {

                        paramssl = this.params

                    }


                    if(paramssl){
                        this.axios.get('filters/competencyIdWithRegionId', {
                            params: paramssl
                        }).then(response => {
                            if(this.filters){
                                this.filters['competencyIdWithRegionId'] = response.data.data
                            }
                            this.$set(this.paginations, 'competencyIdWithRegionId', response.data.meta)
                            this.loadingSMU = false
                        })
                    }

                }, 2000);

            },
            smuFilteredNoSl(){

                if(this.params){
                
                    this.axios.get('filters/competencyIdWithRegionId', {
                        params: this.params
                    }).then(response => {
                        this.filters['competencyIdWithRegionId'] = response.data.data
                        this.$set(this.paginations, 'competencyIdWithRegionId', response.data.meta)
                        this.loadingSMU = false
                    })
                    
                }
                
            },
            udnFilteredSector(sector){

                // this.loadingUdn = true
                let paramsquery = null

                if(sector){
                    paramsquery = {
                        ...this.params,
                        sector:sector ? sector : null
                    }
                } else {
                    paramsquery = {
                        ...this.params
                    }
                }

                this.axios.get('filters/udn', {
                    params: paramsquery
                }).then(response => {
                    this.filters['udn'] = response.data.data
                    this.$set(this.paginations, 'udn', response.data.meta)
                    // this.loadingUdn = false
                })

            },
            goTo(route) {
                this.openFilters = false
                this.showComments = false
                this.$router.push( { name: route } )
            },
            toggleComments(val) {
                if (val === undefined) { this.showComments = !this.showComments }
                else { this.showComments = val }
            },
            toggleFilters() {
                this.openFilters = !this.openFilters
                this.generalfilters = !this.generalfilters
            },
            getSeeDataAs(){

                if(state.user.is_admin == 1 || state.seeDataAs){

                    this.axios.get('user/impersonateUser').then(response => {

                        // if(state.seeDataAs){

                        //     this.seeDataAsOptions = [
                        //         {
                        //             name: 'RETURN YOUR USER',
                        //             id: null
                        //         },
                        //         ...response.data.data
                        //     ]

                        // } else {

                            this.seeDataAsOptions = response.data.data

                        // }

                    });

                }

            },
            setNewToken(newtoken){

                if( this.$route.query.token){
                    this.$router.replace({'query': null});
                }
                
                if(newtoken){

                    if(newtoken.id && newtoken.id != null){

                        actions.setSeeDataAs(state.token)

                        this.axios.get('user/impersonate', {params:{id:newtoken.id}}).then( response => {

                            actions.setToken(response.data.data.token)
                            actions.setChangeDataAs();

                        })
                        

                    } else {

                        actions.setReloadHome(true);
                        actions.setToken(state.seeDataAs)
                        actions.setSeeDataAs(null)
                        actions.setChangeDataAs();

                    }

                    this.$emit('closeFilters')

                }

            }
        },
        computed: {
            userData() {
                return state.user 
            },
            filters() {
                let val = false
                Object.keys(this.hierarchyFilters).forEach((key) => {
                    if (this.hierarchyFilters[key].length > 0) { val = true }
                })
                return (val) ? this.hierarchyFilters : null
            },
            defaultSelectedRol() {
                return this._.find(this.userData.roles, {
                    role_type_id: this.userData.role_default.role_type_id,
                    gui_role_specific_id: this.userData.role_default.gui_role_specific_id,
                })
            },
            periodParams() {
                if (this.timeModel && this.timeModel.start && this.timeModel.end) {
                    return {
                        periodFrom: this.$moment(this.timeModel.start, this.timeFormat)
                            .format(this.timeFormat),
                        periodTo: this.$moment(this.timeModel.end, this.timeFormat)
                            .format(this.timeFormat)
                    }
                }
                return {}
            },
            componentRolesParams() {
                if (this.selectedRolTemp) {
                    return {
                        roleTypeId: this.selectedRolTemp.role_type_id,
                        gui_role_specific_id: this.selectedRolTemp.gui_role_specific_id
                    }
                }
            },
            hierarchyParams() {
                let obj = {}
                for (let el in this.selectedFilters) {
                    if (this.selectedFilters[el].length) {
                        obj[el] = []
                        for (let i in this.selectedFilters[el]) {
                            obj[el].push(this.selectedFilters[el][i].filter_id)
                        }
                    }
                }
                return obj
            },
            periodProjectParams() {
                if (this.selectedPeriod) {
                    return {
                        period: this.selectedPeriod
                    }
                }
            },
            componetParams() {
                return {
                    ...this.periodParams,
                    ...this.componentRolesParams,
                    ...this.hierarchyParams,
                    ...this.periodProjectParams
                }
            },
            globalParams() { 
                return state.globalParams 
            },
            path() {
                return this.$route.path
            },
            Mobilepath() {
                return this.$route.path.split('/')[2]
            },
            completePath(){
                return this.$route.path
            },
            periodParamsPretty() {
                if (this.timeModel && this.timeModel.start && this.timeModel.end) {
                    return {
                        From: this.$moment(this.timeModel.start,this.timeFormat)
                            .format(this.timeFormat),
                        To: this.$moment(this.timeModel.end,this.timeFormat)
                            .format(this.timeFormat)
                    }
                }
                return {}
            },
            hierarchyParamsPretty() {
                let obj = {}
                for (let el in this.selectedFilters) {
                    if (this.selectedFilters[el].length) {
                        obj[el] = []
                        for(let i in this.selectedFilters[el]) {
                            obj[el].push(this.selectedFilters[el][i].filter_name)
                        }
                    }
                }
                return obj
            },
            componetParamsPretty() {
                let obj = {}
                obj['rol'] = this.selectedRolTemp
                if (this.timeModel && this.timeModel.start && this.timeModel.end) {
                    obj['time'] = this.periodParamsPretty
                }
                obj['hierarchy'] = this.hierarchyParamsPretty;
                obj['period'] = this.periodProjectParamsPretty;
                return obj
            },
            periodProjectParamsPretty() {
                if (this.selectedPeriodName) {
                    return {
                        id: this.selectedPeriod,
                        name: this.selectedPeriodName
                    }
                }
            },
            params() {

                let params = state.globalParams

                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }

                return params
            
            },
            period(){
                return state.defaultTimePeriod
            },
            slSelected(){
                return this.selectedFilters['sl_zeus'] ? this.selectedFilters['sl_zeus'].map(item => item.filter_id) : null
            },
            sectorSelected(){
                return this.selectedFilters['sector'] ? this.selectedFilters['sector'].map(item => item.filter_id) : null
            },
            ifInterIntraExtend(){

                if(this.$route.path.split('/')[2] == 'InterIntra' && this.$route.path.split('/')[3]){
                    return true
                } else {
                    return false
                }

            },
            ifInterIntraExtendMobile(){

                // return true

                if(this.$route.path.split('/')[2] == 'InterIntra' && this.$route.path.split('/')[3]){
                    return true
                } else {
                    return false
                }

            },
            interIntraSL(){
                return state.interIntraSL
            },
            idSL(){
                return this.$route.params.idSL ? this.$route.params.idSL : null
            },
            tnrpermissions(){

                if(state.rolSelected.role_type_link && (state.rolSelected.role_type_link == 'presidencia' || state.rolSelected.role_type_link == 'slLeader' || state.rolSelected.role_type_link == 'competencyLeader'|| state.rolSelected.role_type_link == 'FSO') && state.periodSelected != 'Accumulated'){
                    return true
                } else {
                    return false
                }

            },
            ifPersonalDashboard(){

                let res = this.path.split('/');

                if(res[2] == 'PersonalDashboard'){
                    return true
                } else {
                    return false
                }

            },
            seeFilters(){

                var response = false;

                if(_.isEqual(this.componetParams, this.globalParams)){

                    response = false;
                
                } else {
                    response = true;
                }

                if(state.old_token || this.seeDataAs){

                    response = true

                }

                return response

            },
            otherToken(){
                return state.seeDataAs
            }
        },
        watch:{
            slSelected(n,o){
                if(JSON.stringify(n) !== JSON.stringify(o)){
                    this.smuFilteredSL(n);
                    this.selectedFilters.competencyIdWithRegionId ? this.selectedFilters.competencyIdWithRegionId = [] : false
                }            
            },
            sectorSelected(n,o){
                if(JSON.stringify(n) !== JSON.stringify(o)){
                    this.udnFilteredSector(n);
                    this.selectedFilters.udn ? this.selectedFilters.udn = [] : false
                }
            },
            idSL:{
                immediate: true,
                handler(n,o){
                    n ? this.smuFilteredSL([n]) : this.smuFilteredNoSl();
                }
            },
            'filters.competencyIdWithRegionId'(n,o){
                if(!o){

                    // this.idSL ? this.smuFilteredSL([this.idSL]) : this.smuFilteredNoSl()
                }
            },
            path(n,o){
                if (this.mobile) {
                    if(n.split('/')[3] !== 'InterIntraMobileGraph'){
                        actions.setInterIntraSL(null)
                    }
                }
            },
            userData(){
                if(this.mobile){
                    // this.$router.push({name:'homeMobile'})
                }
            }
        },
        mounted() {
            this.setPeriod();
            // this.setDefaultOptions();
        }
    }
</script>

<style>

    

</style>